header {
  background-color: #323232;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  width: fit-content;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 200px;
}

.free_h1_text {
  font-weight: 600;
  margin-top: 0em;
}

.display_flex {
  display: flex;
}

.get_it_text {
  background-color: #8f4fff;
  border: solid 1px white;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 20px;
  text-decoration: none;
  color: white;
  margin-top: 1em;
  margin-right: 1em;
}

.max_width_40 {
  max-width: 40%;
}

.h1_text {
  margin-top: 1em;
  text-align: center;
  padding: 20px;
}

.max_width_100 {
  max-width: 100%;
}

.width_40 {
  width: 40px;
}

.upload_progress::-webkit-scrollbar {
  width: 5px;
}

.upload_progress::-webkit-scrollbar-track {
  background: #fff;
}

.upload_progress::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}

.tags_block {
  display: flex;
  /* margin-top: 1em; */
  flex-wrap: wrap;
}

.width_320 {
  width: 320px;
}

.tags_block div {
  background-color: #8f4fff;
  color: white;
  padding: 5px 12px;
  border-radius: 12px;
  margin-right: 0.5em;
  font-weight: 200;
}

.empty_sessions_div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 2em;
}

.new_session_div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.margin_top_1 {
  margin-top: 1em;
}

.margin_top_1_qr {
  margin-top: 1em;
  width: 80px;
}

.margin_right_1 {
  margin-right: 1em;
}

.empty_text {
  font-size: 18px;
}

.qr_text {
  margin-top: 2em;
  max-width: 310px;
  text-align: center;
  font-size: 18px;
}

.empty_h1 {
  font-size: 25px;
  font-weight: 500;
  margin-top: 1em;
}

.tags_block button {
  background-color: #8f4fff;
  color: white;
  padding: 5px 12px;
  border-radius: 12px;
  margin-right: 0.5em;
  font-weight: 200;
}

.tags_block button .active {
  background-color: #8f4fff;
  color: white;
  padding: 5px 12px;
  border-radius: 12px;
  margin-right: 0.5em;
  font-weight: 200;
}

.patient {
  font-weight: 700;
  color: #3d3838;
}

.timestamp {
  color: #797979;
  font-size: 14px;
}

.tags_style {
  color: #797979;
  font-size: 14px;
}

.date {
  color: #3d3838;
  font-weight: 700;
  font-size: 18px;
}

.label {
  text-transform: uppercase;
  color: #3d3838;
  font-weight: 500;
  font-size: 14px;
}

textarea {
  background: #ffffff;
  border: 1px solid #efefef;
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  padding-top: 15px;
  font-size: 16px;
  resize: none;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
  padding-top: 10px;
  height: 5.5vh;
  border-radius: 10px;
}

.dots_session:hover,
.hover_session:hover,
.hover_session:hover + .dots_session {
  opacity: 1;
  cursor: pointer;
}

.dots_session:hover .hover_session {
  opacity: 1;
  cursor: pointer;
}

.hover_session.show {
  opacity: 1;
}

.hover {
  position: relative;
}

.hover_session {
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  border-radius: 10px;
  height: 83%;
  background: linear-gradient(
    180deg,
    rgba(10, 10, 10, 0.763) 0%,
    rgba(10, 10, 10, 0.249) 30%,
    rgba(255, 255, 255, 0) 50%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.dots_session {
  position: absolute;
  right: 1em;
  top: 0.5em;
  opacity: 0;
  transition: opacity 0.3s ease;
  background-color: white;
  border-radius: 10px;
}

.tag {
  background-color: #8f4fff;
  border-radius: 5px;
  padding: 10px 20px;
}

.images_page {
  display: grid;
  grid-template-columns: repeat(6, 16%);
  align-items: stretch;
  border-radius: 10px;
  align-content: space-between;
  justify-content: space-between;
}

.images_page img {
  height: 180px;
  cursor: pointer;
}

.images_page img {
  margin-bottom: 0.02em;
}

.add_active .add_active_img {
  display: none;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: relative;
}

.add_active .add_img {
  display: block;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: relative;
}

.add_active:hover .add_active_img {
  display: block;
}

.add_active:hover .add_img {
  display: none;
}

.sticky_menu {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  z-index: 1;
  top: 0em;
  background-color: #fff;
  padding: 15px 20px;
}

.no_patient_id {
  color: #bfbfbf;
  font-weight: 600;
  width: 100%;
}

.edit_modal_input {
  padding-top: 10px;
  padding-left: 10px;
  font-weight: 500;
}

.width_100 {
  width: 100%;
}

.margin_bottom_05 {
  margin-bottom: 0.5em;
}

.no_tags_added_div {
  color: #bfbfbf;
  padding-top: 1em;
  font-weight: 600;
  width: 100%;
}

@media (max-width: 768px) {
  .images_page {
    grid-template-columns: 49.6% 49.6%;
  }
  .images_page img {
    margin-bottom: 0.05em;
  }
}

@media (max-width: 425px) {
  .images_page {
    grid-template-columns: 100%;
  }
  .sticky_menu {
    flex-direction: column;
    align-items: center;
  }
}

.grid {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: stretch;
  height: 209px;
  background-image: url(../images/dashboard/background_cross.png);
  background-position: center;
  border-radius: 10px;
  background-repeat: repeat;
  justify-items: center;
  width: 95%;
}

.grid_image {
  width: 97%;
  height: 104px;
  object-fit: cover;
  margin-bottom: 2px;
  display: block;
  line-height: 1;
}

.billing_portal {
  background-color: #f2994f;
  padding: 6px 12px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  color: white;
  border: none;
  cursor: pointer;
}

.banner_text {
  font-weight: 700;
  margin-bottom: 0.5em;
}

.payment_method_image {
  width: 40px;
  margin-right: 2em;
}

.flex_div {
  display: flex;
}

.top_bar_banner {
  background-color: #f6f6f6;
  padding: 15px;
  border-radius: 10px;
  margin-top: 2em;
  align-items: center;
}

.centered_flex_div {
  display: flex;
  justify-content: center;
}

.centered_items_flex_div {
  display: flex;
  align-items: center;
}

.inactive_session {
  color: #999999;
  font-size: 16px;
}

.search_bar_container {
  margin-top: 0.5em;
  z-index: 1;
  background-color: #fff;
  padding: 10px 0px;
  position: absolute;
  width: 100%;
  left: 0;
}

@media (max-width: 800px) {
  .search_bar_container {
    margin-top: 4em;
  }
}

.search_bar_background {
  padding: 10px 15px;
  box-shadow: 0px 2px 8px rgba(58, 92, 157, 0.14);
  border-radius: 20px;
}

.hide_button {
  text-decoration: underline;
  margin-right: 1em;
}

.manage_subscription {
  color: #8f4fff;
  font-weight: 700;
  font-size: 16px;
}

.manage_full_subscription {
  color: #ff284f;
  font-weight: 700;
  font-size: 16px;
}

.flex_end_div {
  display: flex;
  justify-content: flex-end;
}
.margin_1em {
  margin-top: 1em;
  margin-right: 1em;
}

.hr_style_search {
  border: 1px solid rgb(241 241 241);
  margin-top: 1em;
}

.filter_button_search {
  display: flex;
  margin-top: 1em;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -10px;
}

.font_weight_700 {
  font-weight: 700;
}

.max_width_90 {
  max-width: 90%;
}

.display_search_patient {
  word-wrap: break-word;
  width: 90%;
}

.white_text {
  color: white;
  padding: 0;
}

.margin_right {
  margin-right: 0.5em;
}

.flex_centered_div {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.no_filtered_session {
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  text-align: center;
}

@media (max-width: 800px) {
  .no_filtered_session {
    top: 70%;
  }
}

@media (max-width: 1024px) {
  .grid {
    height: 145px;
  }

  .grid_image {
    height: 72px;
  }
}

.grid img:nth-child(1) {
  border-top-left-radius: 10px;
}
.grid img:nth-child(2) {
  border-top-right-radius: 10px;
}

.grid img:nth-child(3) {
  border-bottom-left-radius: 10px;
}
.grid img:nth-child(4) {
  border-bottom-right-radius: 10px;
}

.grid span:nth-child(1) {
  border-top-left-radius: 10px;
}
.grid span:nth-child(2) {
  border-top-right-radius: 10px;
}

.grid span:nth-child(3) {
  border-bottom-left-radius: 10px;
}
.grid span:nth-child(4) {
  border-bottom-right-radius: 10px;
}

.grid video:nth-child(1) {
  border-top-left-radius: 10px;
}
.grid video:nth-child(2) {
  border-top-right-radius: 10px;
}

.grid video:nth-child(3) {
  border-bottom-left-radius: 10px;
}
.grid video:nth-child(4) {
  border-bottom-right-radius: 10px;
}

.button_no_style {
  background: none;
  border: none;
  cursor: pointer;
  font-family: "Red Hat Display";
}

.gray_new_session {
  margin-top: 1em;
  margin-right: 1em;
  box-shadow: 0px 2px 8px rgba(58, 92, 157, 0.14);
  border-radius: 24px;
  padding: 0px 20px;
  background-color: #efefef;
}

.link_no_style {
  color: inherit;
  text-decoration: none;
}

.add_active {
  background-color: #cfcfcf;
}

.add_active:hover {
  background: rgba(255, 161, 0, 0.03);
  border: 2px solid #8f4fff;
}

.button {
  background-color: #8f4fff;
  font-size: 16px;
  border-radius: 20px;
  padding: 10px 20px;
  border: none;
  width: 100%;
  font-weight: 500;
  color: white;
  cursor: pointer;
}

.button:hover {
  transform: scale(1.015);
  transition: all 0.4s;
}

.button_link {
  background: none;
  color: #8f4fff;
  border: none;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}

.back_link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  margin-bottom: 1em;
}

.gallery_image {
  background: none;
  border: none;
  display: flex;
  align-items: stretch;
  padding: 0;
  cursor: pointer;
}

.rdrDateDisplayWrapper {
  display: none;
}

.name div {
  width: 20em;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  margin-top: 1em;
}

.searchWrapper {
  width: 15em;
}

.calendar_button {
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 2px 8px rgba(58, 92, 157, 0.14);
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 10px;
  color: #999999;
  width: 18em;
}

.tags_button {
  border: 1px solid #efefef;
  box-shadow: 0px 2px 8px rgba(58, 92, 157, 0.14);
  border-radius: 6px;
  color: #999999;
}

.calendar_button:focus {
  color: #8f4fff;
}

#patient_search[type="text" i] {
  background: url(../images/dashboard/search.svg) no-repeat scroll 8px 5px;
  padding-left: 40px;
}

.search_bar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.data_flex_session {
  display: flex;
  justify-content: space-between;
}

.data_flex_session div {
  width: 45%;
}

.data_flex_session_inside {
  margin-top: 0em;
  margin-bottom: 0em;
}

@media (max-width: 560px) {
  .search_bar {
    justify-content: center;
  }
  .data_flex_session {
    display: block;
  }

  .data_flex_session div {
    margin-top: 1em;
    width: 100%;
  }
}

.filter_by {
  display: flex;
  align-items: center;
  color: white;
  background: #8f4fff;
  padding: 8px 12px;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(58, 92, 157, 0.14);
}

.margin_top_wrap {
  margin-top: 10px;
}

.hr_style {
  width: 100%;
  border: 1px solid #d9d9d9;
}

.filter_button div {
  margin-right: 1em;
}

.free_flex {
  display: flex;
  margin-top: 1em;
  align-items: center;
  justify-content: space-around;
}

.free_flex2 {
  display: flex;
  font-size: 30px;
  align-items: center;
  justify-content: space-between;
  line-height: 160%;
}

.free_flex2 div {
  width: 60%;
}

.flex_block {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.flex_sub_block {
  padding: 10px;
  margin-top: 2em;
  width: 48%;
}

.flex_image {
  width: 40%;
}

.flex_text_block {
  width: 60%;
}

.flex_free_banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pricing_table_border {
  margin-top: 8em;
  border: 3px solid #8f4fff;
  width: 90%;
  border-radius: 20px;
  padding: 50px 20px 20px 20px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 5em;
  position: relative;
}

.pricing_table_text {
  background: linear-gradient(
    180deg,
    rgba(143, 79, 255, 1) 0%,
    rgb(177, 132, 255) 100%
  );

  padding: 20px;
  border-radius: 20px;
  color: white;
  text-align: center;
  font-weight: 600;
  position: absolute;
  top: -30px;
  left: 50%;
  width: 80%;
  transform: translate(-50%, 0);
}

.container {
  position: relative;
  height: 95vh;
  margin-bottom: 5em;
}

.content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  text-align: left;
  max-width: 90%;
  padding: 20px;
  color: white;
}

.content h1 {
  font-size: 56px;
  font-weight: 300;
}

.content img {
  width: 40%;
}

.content p {
  font-size: 18px;
  width: 40%;
  margin-top: 2em;
}

.content a {
  display: inline-block;
  background-color: #8f4fff;
  padding: 10px 30px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 20px;
  text-decoration: none;
  color: white;
}

.circle {
  width: 20px;
  height: 20px;
  background-color: #8f4fff;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  margin-right: 1em;
}

.download_button {
  display: flex;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px 20px;
  justify-content: space-between;
  background: transparent;
  align-items: center;
}

.download_button:hover {
  transform: scale(1.05);
  transition: 0.5s all;
}

.download_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 3%;
  font-weight: 700;
}

.download_flex {
  display: flex;
  justify-content: space-evenly;
}

.freeuser_banner {
  width: 100%;
  height: 100%;
}

@media (max-width: 900px) {
  .content {
    width: 100%;
    padding-bottom: 40px;
  }

  .content-overlay {
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  .flex_block {
    flex-wrap: wrap;
  }

  .flex_sub_block {
    width: 100%;
  }

  .flex_image {
    width: 100%;
  }

  .flex_text_block {
    width: 100%;
    text-align: center;
  }

  .flex_free_banner {
    flex-direction: column;
  }

  .pricing_table_border {
    width: 80%;
  }
}

@media (max-width: 600px) {
  .free_flex {
    justify-content: center;
    flex-direction: column;
  }

  .free_flex div {
    width: 100%;
    text-align: center;
    margin-bottom: 1em;
  }

  .free_flex2 {
    justify-content: center;
    flex-direction: column;
  }

  .free_flex2 div {
    width: 100%;
    margin-top: 1em;
  }

  .pricing_table_text {
    padding: 10px;
  }

  .content p {
    width: 95%;
  }

  .freeuser_banner {
    object-fit: none;
  }

  .content img {
    width: 90%;
  }

  .content h1 {
    font-size: 30px;
    text-align: center;
  }

  .content-overlay {
    justify-content: flex-start;
  }
}

@media (max-width: 500px) {
  .container {
    margin-bottom: 5em;
  }

  .download_button {
    padding: 10px;
  }
}
