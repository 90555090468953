.table_header {
  position: sticky;
  top: 0;
  background-color: #e2e2e2; /* Ensure background color is applied */
  z-index: 1; /* Ensure it stays above other content */
}

.custom-border {
  border-top: 1px solid #e0e0e0; /* Top border */
  border-bottom: 1px solid #e0e0e0; /* Bottom border */
  border-left: none; /* No left border */
  border-right: none; /* No right border */
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* Slim scrollbar for WebKit browsers (Chrome, Safari, and Opera) */
.hide-scrollbar::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.hide-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.hide-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb when hovered */
}

/* Slim scrollbar for Firefox */
.hide-scrollbar {
  scrollbar-width: thin; /* Width of the scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Color of the scrollbar thumb and track */
}

.custom-notchedOutline {
  border-color: #797979 !important;
}

.custom-focused {
  color: black !important;
}

.custom-label {
  color: #797979 !important;
}

.custom-label-focused {
  color: #797979 !important;
}

.tableRowHover:hover {
  background-color: #f1e7ff;
}

.tableCellHover {
  border: none;
  position: relative;
}

.tableCellHover:hover {
  border: 1px solid #797979;
  border-radius: 5px;
}

.arrow-on-hover .MuiSelect-icon,
.arrow-on-hover .MuiAutocomplete-popupIndicator {
  visibility: hidden;
}

.arrow-on-hover:hover .MuiSelect-icon,
.arrow-on-hover:hover .MuiAutocomplete-popupIndicator {
  visibility: visible;
}

.no-background {
  background-color: transparent !important;
}
