/* Dashboard.css */

.orange_button {
  background-color: #f2994f;
  padding: 6px 12px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  color: white;
  border: none;
}

.top_bar_container {
  position: sticky;
  z-index: 1;
  top: -1px;
  background-color: #fff;
  padding: 15px 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 600px) {
  .top_bar_container {
    justify-content: center;
  }
}

.refresh_button {
  display: flex;
  align-items: center;
  margin-top: 1em;
  margin-right: 1em;
  box-shadow: 0px 2px 8px rgba(58, 92, 157, 0.14);
  border-radius: 24px;
  padding: 0px 20px;
}

.search_button {
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 8px rgba(58, 92, 157, 0.14);
  border-radius: 24px;
  padding: 0px 20px;
  margin-top: 1em;
}

.search-container {
  background-color: white;
  padding-bottom: 10px;
  width: 100%;
  left: 0;
  position: absolute;
}

.search-container.display-search {
  background-color: unset;
}

.search-container.with-padding {
  margin-top: 8em;
}

.search-container.without-padding {
  margin-top: 0.5em;
}

.search-container.with-tags {
  padding-top: 1em;
}

.search-container.without-tags {
  padding-top: 0;
}

@media (max-width: 920px) {
  .search-container.with-padding {
    margin-top: 11em;
  }
}

@media (max-width: 800px) {
  .search-container.without-padding {
    margin-top: 4em;
  }
  .search-container.with-padding {
    margin-top: 15em;
  }
}

@media (max-width: 650px) {
  .search-container.with-padding {
    margin-top: 18em;
  }
}

.margin-top-14em {
  margin-top: 13em;
}

.margin-top-11em {
  margin-top: 8em;
}

.margin-top-9em {
  margin-top: 6em;
}

.margin-top-2em {
  margin-top: 2em;
}

@media (max-width: 920px) {
  .margin-top-14em {
    margin-top: 15em;
  }

  .margin-top-11em {
    margin-top: 11em;
  }
}

@media (max-width: 800px) {
  .margin-top-14em {
    margin-top: 16em;
  }
}

@media (max-width: 620px) {
  .margin-top-14em {
    margin-top: 20em;
  }

  .margin-top-11em {
    margin-top: 14em;
  }

  .margin-top-9em {
    margin-top: 8em;
  }
}

.tableRowHover:hover {
  background-color: var(--action-hover, #0000000a);
  cursor: pointer;
}

.calendar_button {
  width: "14em";
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 2px 8px rgba(58, 92, 157, 0.14);
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 10px;
  color: #999999;
  width: 18em;
}

.button_text {
  color: #3d3838;
  font-size: 16px;
}

.owner_text {
  color: #8f4fff;
}

.payment_banner_div {
  margin-top: 1em;
  background-color: #fff4e5;
  padding: 20px 20px;
  border-radius: 10px;
  font-weight: 500;
  justify-content: space-between;
}

.font_weight_500 {
  font-weight: 500;
}

.checked_owner_number {
  background-color: white;
  color: #8f4fff;
  border-radius: 10px;
  margin-left: 5px;
  display: inline-block;
  padding: 4px 8px 4px 8px;
}

.owner_filter_div {
  position: absolute;
  background-color: white;
  box-shadow: 0px 2px 8px 0px #3a5c9d24;
  z-index: 10;
  padding: 20px;
  border-radius: 10px;
  margin-top: 10px;
  max-width: 300px;
  left: 65%;
  transform: translateX(-35%);
  min-width: 300px;
}

.font_weight_600 {
  font-weight: 600;
}

.margin_bottom_0 {
  margin-bottom: 0;
}

.checkbox_owner {
  display: flex;
  align-items: center;
  margin-top: 1em;
}

.checkbox_owner_name {
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  margin-left: 10px;
}

.width_20 {
  width: 20px;
}

.owner_name {
  word-break: break-all;
}

.margin_top_wrap {
  margin-top: 10px;
}

.date_picker div {
  position: absolute;
  background-color: white;
  z-index: 10;
  border-radius: 10px;
  width: 20em;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  margin-top: 1em;
}

.date_separator {
  margin-bottom: 1em;
  color: #3d3838;
  font-weight: 700;
  font-size: 18px;
}

.session_item {
  width: 95%;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: stretch;
  height: 209px;
  border-radius: 10px;
  justify-items: center;
}

.grid_session_item {
  margin-bottom: 1.5em;
  position: relative;
  width: 95%;
}

.session_info_container {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
  width: 95%;
}

.session_time {
  display: flex;
}

.session_dots_container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.session_dots_button {
  align-self: flex-end;
}

.input_style {
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 2px 8px rgba(58, 92, 157, 0.14);
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 16px;
  resize: none;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  height: 2.5em;
  border-radius: 10px;
  font-family: Red Hat Display, sans-serif;
}

.overview_text {
  padding-left: 20px;
  margin-top: 2em;
  font-size: 14px;
}

.input_style:focus,
textarea:focus {
  border-color: #8f4fff !important;
}

.edit_session_button {
  color: black;
  margin: 0;
}

.edit_session_icon {
  max-width: 100%;
  width: 27px;
}

.menu_edit_button_text {
  margin-right: 1em;
}

.menu_edit_button_icon {
  max-width: 100%;
}

.modal_header_div {
  background-color: #fdfdfd;
  padding: 20px 0px;
}

.modal_subheader_div {
  display: flex;
  justify-content: center;
  background-color: white;
  padding-bottom: 1em;
  box-shadow: 0px 4px 4px 0px rgba(50, 50, 71, 0.08);
}

.modal_subheader_text {
  font-weight: 700;
}

.modal_deletion_text {
  text-align: center;
  margin-top: 2em;
}

.patient_div {
  margin-top: 1em;
  padding: 30px;
}

.tags_div {
  text-align: center;
  margin-bottom: 1em;
  margin-top: 1em;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 2px 8px rgba(58, 92, 157, 0.14);
  padding: 0px 20px;
}

.tag_inside_div {
  display: flex;
  margin-right: 0.5em;
  margin-bottom: 20px;
}

.add_tag_div {
  text-align: right;
  margin-top: 1em;
  display: flex;
  align-items: center;
}

.add_tag_textarea_div {
  width: 70%;
  margin-right: 1em;
}

.margin_bottom_1 {
  margin-bottom: 1em;
}

.flex_div_center {
  display: flex;
  justify-content: center;
}

.modal_deletion_buttons_div {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 1em;
}

.modal_deletion_button_cancel {
  margin-right: 1em;
  font-weight: 600;
  font-size: 18px;
}

.modal_deletion_button_delete {
  color: #8f4fff;
  font-weight: 600;
  font-size: 18px;
}

.session_patient_id {
  color: #8f4fff;
  font-weight: 500;
  word-wrap: break-word;
  margin: 0;
  margin-bottom: 1em;
}

.tags_div {
  word-wrap: break-word;
  display: flex;
  margin-right: 0.5em;
  width: auto;
}

.comment_title {
  color: #797979;
  margin-bottom: 0.5em;
  font-weight: 600;
  margin-top: 0;
}

.session_hr {
  margin-top: 1em;
  margin-bottom: 1em;
  border: solid 0.7px #d9d9d9;
}

.margin_bottom_2 {
  margin-bottom: 2em;
}

.div_centered {
  display: flex;
  align-items: center;
}

.add_image_text {
  font-weight: 600;
  margin-left: 0.5em;
}

.upload_info_text {
  font-size: 13px;
  margin-top: 2em;
}

.div_tab_panel {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  padding: 20px;
  border-radius: 10px;
  margin-top: 2em;
}

.margin_right_2 {
  margin-right: 2em;
}

.upload_text {
  font-weight: 700;
  margin-bottom: 0.5em;
}

.upgrade_link {
  color: #8f4fff;
  text-decoration: underline;
}

.users_span {
  font-weight: 700;
  font-size: 20px;
}

.users_text_color {
  color: #999999;
}

.users_icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.margin_left_10 {
  margin-left: 10px;
}

.users_permissions_div {
  font-weight: 700;
  margin-bottom: 0.5em;
}

.contact_us_button {
  padding: 10px 40px;
  color: white;
  border-radius: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  justify-content: center;
}

.button_no_style.contact_us_button {
  background-color: #8f4fff;
}
